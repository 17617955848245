import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home.vue';
import Start from '../views/Start.vue';
import Employee from '../views/Employee.vue';
import Contactor from '../views/Contactor.vue';
import ConNewpermit from '../components/ConNewpermit.vue';
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/start',
        name: 'Start',
        component: Start
    },
    {
        path: '/employee',
        name: 'Employee',
        component: Employee
    },
    {
        path: '/contactor',
        name: 'Contactor',
        component: Contactor
    },
    {
        path: '/connewpermit',
        name: 'ConNewpermit',
        component: ConNewpermit
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
