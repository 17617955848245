import { ref, } from 'vue';
import { Plugins, CameraResultType, CameraSource, Capacitor } from "@capacitor/core";
const photos = ref([]);
export function usePhotoGallery() {
    // const { Camera, Filesystem } = Plugins;
    const { Camera } = Plugins;
    // const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    //   const reader = new FileReader;
    //   reader.onerror = reject;
    //   reader.onload = () => {
    //       resolve(reader.result);
    //   };
    //   reader.readAsDataURL(blob);
    // });
    // const savePicture = async (photo: CameraPhoto, fileName: string): Promise<Photo> => {
    //   let base64Data: string;
    //   // Fetch the photo, read as a blob, then convert to base64 format
    //   const response = await fetch(photo.webPath!);
    //   const blob = await response.blob();
    //   base64Data = await convertBlobToBase64(blob) as string;
    //   const savedFile = await Filesystem.writeFile({
    //     path: fileName,
    //     data: base64Data,
    //     directory: FilesystemDirectory.Data
    //   });
    //   // Use webPath to display the new image instead of base64 since it's 
    //   // already loaded into memory
    //   return {
    //     filepath: fileName,
    //     webviewPath: photo.webPath
    //   };
    // }
    const takePhoto = async () => {
        const isCameraAvailable = Capacitor.isPluginAvailable('Camera');
        if (!isCameraAvailable) {
            console.log('!isCameraAvailable');
        }
        else {
            console.log('isCameraAvailable');
        }
        try {
            const cameraPhoto = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 100,
                allowEditing: true,
                correctOrientation: true
            });
            console.log('cameraPhoto', cameraPhoto);
            // console.log('data:image/png;base64,' + cameraPhoto.base64String)
            const fileName = new Date().getTime() + '.jpeg';
            const savedFileImage = {
                filepath: fileName,
                webviewPath: cameraPhoto.webPath
            };
            photos.value = [savedFileImage, ...photos.value];
            console.log('photos', photos);
        }
        catch (error) {
            console.log(error);
        }
    };
    //   return from(Camera.getPhoto(options).then(photo => {
    //     return 'data:image/png;base64,' + photo.base64String;
    // }).catch(err => {
    //     console.error('Error: ', err);
    // }));
    return {
        photos,
        takePhoto
    };
}
