import { IonContent, IonPage, IonSlides, IonSlide, IonIcon, IonButton, } from "@ionic/vue";
import { useRouter } from 'vue-router';
import { defineComponent } from "vue";
import { add, archiveOutline, albumsOutline, arrowForward, } from "ionicons/icons";
export default defineComponent({
    name: "Home",
    components: {
        IonIcon,
        IonButton,
        IonContent,
        IonPage,
        IonSlides,
        IonSlide,
    },
    setup() {
        const slideOpts = {
            initialSlide: 0,
            speed: 400,
        };
        const icons = {
            archiveOutline,
            albumsOutline,
            arrowForward,
            add,
        };
        return { router: useRouter(), slideOpts, icons };
    },
    async mounted() {
        if (localStorage.getItem('user') != null) {
            const user = await JSON.parse(localStorage.user);
            if (user.type == "con") {
                window.location.replace('./contactor');
            }
            else if (user.type == "emp") {
                window.location.replace('./employee');
            }
        }
    }
});
