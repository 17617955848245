import { alertController, IonButton, IonAvatar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle } from "@ionic/vue";
import { enterOutline } from "ionicons/icons";
import { defineComponent } from "vue";
export default defineComponent({
    name: "ConProfile",
    props: ['user'],
    components: { IonButton, IonAvatar, IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle },
    setup() {
        const icons = { enterOutline };
        return { icons };
    },
    methods: {
        logout() {
            localStorage.removeItem('user');
            window.location.replace('./home');
        },
        async presentAlertConfirm() {
            const alert = await alertController
                .create({
                header: 'Logout',
                message: 'ยืนยันการออกจากระบบ',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                    },
                    {
                        text: 'Logout',
                        handler: () => {
                            this.logout();
                        },
                    },
                ],
            });
            return alert.present();
        },
    }
});
