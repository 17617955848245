/* eslint-disable no-console */
import { register } from 'register-service-worker';
let swRegistration;
let isSubscribed;
const applicationServerPublicKey = 'BPiMV6t7GsPhSXlVzInnKJjLyfgN8dwfhUOQ2FNcd0EPKyUgG17W7wNqKdxkb3227-F6V3S5TfRtDm4G9JASE54';
const self = window;
function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    // const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
self.addEventListener('push', function (event) {
    console.log('[Service Worker] Push Received.');
    console.log(`[Service Worker] Push had this data: "${event.data.text()}"`);
    const title = 'Push Codelab';
    const options = {
        body: 'Yay it works.',
        icon: 'images/icon.png',
        badge: 'images/badge.png'
    };
    event.waitUntil(swRegistration.showNotification(title, options));
});
self.addEventListener('notificationclick', function (event) {
    console.log('[Service Worker] Notification click Received.');
    event.notification.close();
    // event.waitUntil(
    //   clients.openWindow('https://developers.google.com/web/')
    // );
});
self.addEventListener('pushsubscriptionchange', function (event) {
    console.log('[Service Worker]: \'pushsubscriptionchange\' event fired.');
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
    event.waitUntil(swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
    })
        .then(function (newSubscription) {
        // TODO: Send to application server
        console.log('[Service Worker] New subscription: ', newSubscription);
    }));
});
function whenRecievePush(registration) {
    console.log('whenRecievePush..');
    self.addEventListener('push', function (event) {
        console.log('[Service Worker] Push Received.');
        console.log(`[Service Worker] Push had this data: "${event.data.text()}"`);
        const title = 'Push Codelab';
        const options = {
            body: 'Yay it works.',
            icon: 'images/icon.png',
            badge: 'images/badge.png'
        };
        event.waitUntil(registration.showNotification(title, options));
        event.waitUntil(swRegistration.showNotification(title, options));
    });
}
function updateSubscriptionOnServer(subscription) {
    // TODO: Send subscription to application server
    if (subscription) {
        console.log(JSON.stringify(subscription));
    }
    else {
        console.log('no subscription');
    }
}
function subscribeUser() {
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
    swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
    })
        .then(function (subscription) {
        console.log('User is subscribed.');
        updateSubscriptionOnServer(subscription);
        isSubscribed = true;
    })
        .catch(function (err) {
        console.log('Failed to subscribe the user: ', err);
    });
}
function doSubscription() {
    // Set the initial subscription value
    swRegistration.pushManager.getSubscription()
        .then(function (subscription) {
        isSubscribed = !(subscription === null);
        updateSubscriptionOnServer(subscription);
        if (isSubscribed) {
            console.log('User IS subscribed.');
            whenRecievePush(swRegistration);
        }
        else {
            console.log('User is NOT subscribed.');
            subscribeUser();
        }
    });
}
// if (process.env.NODE_ENV === 'production') {
if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'development') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('service worker is ready..');
            // console.log(
            //   'App is being served from cache by a service worker.\n' +
            //   'For more details, visit https://goo.gl/AFskqB'
            // )
        },
        registered(swReg) {
            console.log('Service worker has been registered.');
            swRegistration = swReg;
            console.log('swRegistration', swRegistration);
            // must be after get user
            // doSubscription()
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated() {
            console.log('New content is available; please refresh.');
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
else {
    console.log('this is not production..');
}
/**
 * {"endpoint":"https://sg2p.notify.windows.com/w/?token=BQYAAADvi1fcc3rxCUV01z28DSsTZvux0b%2b2JR27KYNCqIhocP3mYbb3WMDV%2bA0OvEmIMHTzEv9NTQNrz%2fJYVFKCdQPL2pfdZMPudBf5UtHmnp9rua%2f8BAQX%2bXnUhtv4V6KMzzRoz3ott6wCd7AbZv8F3KJtQ6mE1CDwZsrjX1UWIXLFVpD8BuodTcxddM4dJltfG84TABRVVID9f%2b6JEB%2fRz2GO2Ns%2ff3DB8TYPzgdb3Jq6mzvKCimnhs1I0AMx0vFHIsHN6f2GF3Jm%2fooM5H5E5l2PgFTYHIiX4qcxYP0EQ1k2nVbZR0b6NNEth0DVazMOZfE%3d","expirationTime":null,"keys":{"p256dh":"BK78rVtVR0zEg7X6N7UQWDH2vq3kQduR_KcMxsCaraYCJ7ccIrEGL2gy7gsfBtTMMYF4gO52WjC5kwb7FIyKscE","auth":"Y6hWNcyi-befWQjJtmyySQ"}}
 */ 
