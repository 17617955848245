import { IonList, IonItem, IonLabel, IonIcon, IonAvatar, } from "@ionic/vue";
import { document, documentOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import moment from 'moment';
export default defineComponent({
    name: "ConPermit",
    props: ["apms"],
    components: {
        IonList, IonItem, IonLabel, IonIcon, IonAvatar,
    },
    setup() {
        const icons = { document, documentOutline };
        return { icons };
    },
    data() {
        return {
            APMUriEndpoint: "https://apmapi.pealive.com",
            // apms: [],
            contactor: { _id: { $oid: "" } }
        };
    },
    methods: {
        thaiDate(tm) {
            return moment(tm).format('DD MMMM YYYY');
        },
    },
    async mounted() {
        console.log('apms', this.apms);
        // this.contactor = await JSON.parse(localStorage.user)
        // await this.getAPMS(this.contactor._id.$oid)
    }
});
